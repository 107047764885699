import {Injectable} from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse,
	HttpSentEvent
} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {StatusCodes} from 'http-status-codes';
import {UserAccountManagementService} from '../../user-account-management/user-account-management.service';
import {Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {DialogService} from '../../../shared/dialogs/dialog-service';
import {AUTHENTICATION_HEADER_KEYS, InterceptorSkipAuthenticationHeader} from '../authentication-keys';
import {AuthenticationService} from '../authentication.service';

@Injectable({
	providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

	constructor(private authService: AuthenticationService,
	            private userService: UserAccountManagementService,
	            private router: Router,
	            private dialogService: DialogService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (this.authService.hasApiToken() && this.authService.isApiTokenExpired() && !AuthInterceptor.isCurrentRouteLogin(request)) {
			this.userService.clearUserData();
			this.dialogService.closeAllDialogs();
			this.router.navigate(['/login']).then();
			return EMPTY;
		}
		const url: string = AuthInterceptor.shouldSkipAddingFullUrl(request) ? request.url : `${environment.apiServerUrl}/${request.url}`;
		const requestWithFullUrl: HttpRequest<any> = request.clone({url});
		const apiToken: string = this.authService.getApiToken();
		let requestToHandle: HttpRequest<any>;
		if (AuthInterceptor.shouldNotIntercept(request)) {
			requestToHandle = request.clone({url, headers: request.headers.delete(InterceptorSkipAuthenticationHeader)});
		} else {
			requestToHandle = AuthInterceptor.addApiTokenToHeaders(requestWithFullUrl, apiToken);
		}

		return next.handle(requestToHandle)
			.pipe(filter((event: HttpSentEvent) => event instanceof HttpResponse),
				catchError((error: any) => {
					// no login yet, just re-emit the error for processing to continue.
					// we have nothing else to do with this request.
					if (apiToken == null) {
						return throwError(error);
					}
					// either we tried to access something we are not allowed to, or the api token
					// was invalidated and no longer is available. In all cases, safest thing
					// to do is logout the user.
					if (error.status === StatusCodes.FORBIDDEN || !this.authService.hasApiToken()) {
						// emit original error so can be further processed when necessary. otherwise subscriber
						// will receive data it does not expect and / or processing might get stuck.
						return this.logoutAndThrowError(error);
					}
					return throwError(error);
				}));
	}

	private logoutAndThrowError(error: HttpErrorResponse): Observable<any> {
		return this.logout().pipe(
			catchError(() => throwError(error)),
			switchMap(() => throwError(error))
		);
	}

	private static shouldNotIntercept(request: HttpRequest<any>): boolean {
		return request.headers.has(InterceptorSkipAuthenticationHeader) || AuthInterceptor.isCurrentRouteLogin(request);
	}

	private static addApiTokenToHeaders(request: HttpRequest<any>, apiToken: string): HttpRequest<any> {
		const skipAuth: boolean = request.url.includes('public') || apiToken == null;
		if (skipAuth) {
			return request;
		}
		const headers: HttpHeaders = request.headers.append(AUTHENTICATION_HEADER_KEYS.apiToken, `Bearer ${apiToken}`);
		return request.clone({headers});
	}

	private logout(): Observable<HttpEvent<any>> {
		return this.authService.logout();
	}

	private static isCurrentRouteLogin(request: HttpRequest<any>): boolean {
		return request.url.includes('login');
	}

	private static shouldSkipAddingFullUrl(request: HttpRequest<any>): boolean {
		return request.url.includes('https://');
	}
}

<aga-text-editor id="daily-note-text-editor"
                 [ngModel]="newNote || dailyNote.note"
                 [disabled]="!isEditMode"
                 (ngModelChange)="changeDailyNote($event)"></aga-text-editor>
<div *ngIf="dailyNote.createdByEmployeeUuid === loggedInEmployeeUuid && !(disabled || isEditMode)"
     class="d-inline-flex">
	<button id="delete-daily-note"
	        class="delete-button"
	        [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager, UserRole.MedicalTeam, UserRole.Counselor]"
	        [tooltip]="'toolTip.delete' | translate"
	        container="body"
	        (click)="openDeleteDailyNoteDialog()">
		<img src="../../../../../assets/icons/svg/ic_Delete_24x24.svg"
		     alt="delete-daily-note-icon">
	</button>
	<button id="edit-daily-note"
	        class="edit-button"
	        [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager, UserRole.MedicalTeam, UserRole.Counselor]"
	        [tooltip]="'toolTip.edit' | translate"
	        container="body"
	        (click)="editDailyNote()">
		<img src="../../../../../assets/icons/svg/ic_Edit_24x24.svg"
		     alt="edit-daily-note-icon">
	</button>
</div>
<div *ngIf="isEditMode"
     class="d-inline-flex"
     [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager, UserRole.MedicalTeam, UserRole.Counselor]">
	<img class="no-icon"
	     id="reset-daily-note-icon"
	     src="../../../../../assets/icons/svg/ic_No_24x24.svg"
	     alt="cancel-daily-note-change"
	     (click)="resetDailyNote()">
	<img class="yes-icon"
	     id="update-daily-note-icon"
	     src="../../../../../assets/icons/svg/ic_Yes_Light_24x24.svg"
	     alt="confirm-daily-note-change"
	     (click)="updateDailyNote()">
</div>

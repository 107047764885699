import {ModuleWithProviders, NgModule} from '@angular/core';
import {StompjsService} from './stompjs.service';
import {RxStompConfig} from '@stomp/rx-stomp';

@NgModule()
export class StompjsModule {
	static forRoot(config: RxStompConfig): ModuleWithProviders<StompjsModule> {
		return {
			ngModule: StompjsModule,
			providers: [
				{provide: 'rxStompConfig', useValue: config},
				StompjsService
			]
		};
	}
}

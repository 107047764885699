import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DailyNote} from './models/daily-note.model';
import {UserAccountManagementService} from '../../../../core/user-account-management/user-account-management.service';
import {UserRole} from '../../../../core/models/user-role.enum';
import {DialogService} from '../../../../shared/dialogs/dialog-service';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'aga-daily-note',
	templateUrl: './daily-note.component.html',
	styleUrls: ['./daily-note.component.scss']
})
export class DailyNoteComponent implements OnInit {
	@Input() dailyNote: DailyNote;
	@Input() disabled: boolean;
	@Output() delete: EventEmitter<DailyNote> = new EventEmitter<DailyNote>();
	@Output() update: EventEmitter<DailyNote> = new EventEmitter<DailyNote>();

	isEditMode: boolean = false;
	newNote: string;
	UserRole = UserRole;
	loggedInEmployeeUuid: string;

	constructor(private userAccountManagementService: UserAccountManagementService,
	            private dialogService: DialogService,
	            private translateService: TranslateService) {
	}

	ngOnInit() {
		this.loggedInEmployeeUuid = this.userAccountManagementService.currentUser.uuid;
	}

	changeDailyNote(value: string): void {
		this.newNote = value;
	}

	openDeleteDailyNoteDialog(): void {
		const confirmationDialogRef = this.dialogService.openConfirmationDialog(this.translateService.instant('dailyNotesDialog.deleteDailyNote'), {
			leftButtonText: this.translateService.instant('genericButtons.no'),
			rightButtonText: this.translateService.instant('genericButtons.yes')
		});
		confirmationDialogRef.componentInstance.onRightButtonClick = () => {
			this.deleteDailyNote();
			confirmationDialogRef.close();
		};
	}

	deleteDailyNote(): void {
		this.delete.emit(this.dailyNote);
	}

	editDailyNote(): void {
		this.isEditMode = true;
	}

	resetDailyNote(): void {
		this.newNote = '';
		this.isEditMode = false;
	}

	updateDailyNote(): void {
		const newDailyNote = DailyNote.updateNote(this.dailyNote, this.newNote);
		this.update.emit(newDailyNote);
	}
}

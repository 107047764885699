import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DailyNote} from '../daily-notes/models/daily-note.model';
import {format} from 'date-fns';
import {map} from 'rxjs/operators';

@Injectable()
export class ApiDailyNotesService {
	constructor(private http: HttpClient) {
	}

	getDailyNotes(clinicUuid: string, date: Date): Observable<Array<DailyNote>> {
		const params = {
			clinicUuid: clinicUuid,
			date: format(date, 'yyyy-MM-dd')
		};
		return this.http.get(`api/web-ui/medical-record/daily-note`, {params: params}).pipe(
			map((response: Array<any>) => response.map((res: DailyNotesResponse) => DailyNote.deserialize(res))));
	}

	createDailyNote(forDate: Date, clinicUuid: string, note: string): Observable<DailyNote> {
		const body = {
			clinicUuid: clinicUuid,
			note: note,
			appliesTo: format(forDate, 'yyyy-MM-dd')
		};
		return this.http.post(`api/web-ui/medical-record/daily-note`, body).pipe(map((res: DailyNotesResponse) => DailyNote.deserialize(res)));
	}

	updateDailyNote(noteUuid: string, note: string, version: number): Observable<DailyNote> {
		const params = {
			uuid: noteUuid,
			version: version
		};
		const body = {
			note: note
		};
		return this.http.put(`api/web-ui/medical-record/daily-note`, body, {params: params}).pipe(
			map((dailyNote: any) => DailyNote.deserialize(dailyNote))
		);
	}

	deleteDailyNote(noteUuid: string): Observable<any> {
		const params = {
			uuid: noteUuid
		};
		return this.http.delete(`api/web-ui/medical-record/daily-note`, {params: params});
	}

	markNotesAsRead(clinicUuid: string, date: Date): Observable<any> {
		const params = {
			clinicUuid: clinicUuid,
			dailyNotesDate: format(date, 'yyyy-MM-dd')
		};
		return this.http.post(`api/web-ui/medical-record/daily-note/read`, {}, {params: params});
	}
}

export interface DailyNotesResponse {
	uuid: string;
	clinicUuid: string;
	createdByEmployeeUuid: string;
	lastModifiedByEmployeeUuid: string;
	note: string;
	createdAt: string;
	modifiedAt: string;
	seen: boolean;
	version: number;
}

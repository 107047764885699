// Exporting constants directly to maintain compatability with v1
// These are deprecated. Please don't add any new codes here.
/**
 * @deprecated Please use StatusCodes.ACCEPTED
 *
 * */
export var ACCEPTED = 202;
/**
 * @deprecated Please use StatusCodes.BAD_GATEWAY
 *
 * */
export var BAD_GATEWAY = 502;
/**
 * @deprecated Please use StatusCodes.BAD_REQUEST
 *
 * */
export var BAD_REQUEST = 400;
/**
 * @deprecated Please use StatusCodes.CONFLICT
 *
 * */
export var CONFLICT = 409;
/**
 * @deprecated Please use StatusCodes.CONTINUE
 *
 * */
export var CONTINUE = 100;
/**
 * @deprecated Please use StatusCodes.CREATED
 *
 * */
export var CREATED = 201;
/**
 * @deprecated Please use StatusCodes.EXPECTATION_FAILED
 *
 * */
export var EXPECTATION_FAILED = 417;
/**
 * @deprecated Please use StatusCodes.FAILED_DEPENDENCY
 *
 * */
export var FAILED_DEPENDENCY = 424;
/**
 * @deprecated Please use StatusCodes.FORBIDDEN
 *
 * */
export var FORBIDDEN = 403;
/**
 * @deprecated Please use StatusCodes.GATEWAY_TIMEOUT
 *
 * */
export var GATEWAY_TIMEOUT = 504;
/**
 * @deprecated Please use StatusCodes.GONE
 *
 * */
export var GONE = 410;
/**
 * @deprecated Please use StatusCodes.HTTP_VERSION_NOT_SUPPORTED
 *
 * */
export var HTTP_VERSION_NOT_SUPPORTED = 505;
/**
 * @deprecated Please use StatusCodes.IM_A_TEAPOT
 *
 * */
export var IM_A_TEAPOT = 418;
/**
 * @deprecated Please use StatusCodes.INSUFFICIENT_SPACE_ON_RESOURCE
 *
 * */
export var INSUFFICIENT_SPACE_ON_RESOURCE = 419;
/**
 * @deprecated Please use StatusCodes.INSUFFICIENT_STORAGE
 *
 * */
export var INSUFFICIENT_STORAGE = 507;
/**
 * @deprecated Please use StatusCodes.INTERNAL_SERVER_ERROR
 *
 * */
export var INTERNAL_SERVER_ERROR = 500;
/**
 * @deprecated Please use StatusCodes.LENGTH_REQUIRED
 *
 * */
export var LENGTH_REQUIRED = 411;
/**
 * @deprecated Please use StatusCodes.LOCKED
 *
 * */
export var LOCKED = 423;
/**
 * @deprecated Please use StatusCodes.METHOD_FAILURE
 *
 * */
export var METHOD_FAILURE = 420;
/**
 * @deprecated Please use StatusCodes.METHOD_NOT_ALLOWED
 *
 * */
export var METHOD_NOT_ALLOWED = 405;
/**
 * @deprecated Please use StatusCodes.MOVED_PERMANENTLY
 *
 * */
export var MOVED_PERMANENTLY = 301;
/**
 * @deprecated Please use StatusCodes.MOVED_TEMPORARILY
 *
 * */
export var MOVED_TEMPORARILY = 302;
/**
 * @deprecated Please use StatusCodes.MULTI_STATUS
 *
 * */
export var MULTI_STATUS = 207;
/**
 * @deprecated Please use StatusCodes.MULTIPLE_CHOICES
 *
 * */
export var MULTIPLE_CHOICES = 300;
/**
 * @deprecated Please use StatusCodes.NETWORK_AUTHENTICATION_REQUIRED
 *
 * */
export var NETWORK_AUTHENTICATION_REQUIRED = 511;
/**
 * @deprecated Please use StatusCodes.NO_CONTENT
 *
 * */
export var NO_CONTENT = 204;
/**
 * @deprecated Please use StatusCodes.NON_AUTHORITATIVE_INFORMATION
 *
 * */
export var NON_AUTHORITATIVE_INFORMATION = 203;
/**
 * @deprecated Please use StatusCodes.NOT_ACCEPTABLE
 *
 * */
export var NOT_ACCEPTABLE = 406;
/**
 * @deprecated Please use StatusCodes.NOT_FOUND
 *
 * */
export var NOT_FOUND = 404;
/**
 * @deprecated Please use StatusCodes.NOT_IMPLEMENTED
 *
 * */
export var NOT_IMPLEMENTED = 501;
/**
 * @deprecated Please use StatusCodes.NOT_MODIFIED
 *
 * */
export var NOT_MODIFIED = 304;
/**
 * @deprecated Please use StatusCodes.OK
 *
 * */
export var OK = 200;
/**
 * @deprecated Please use StatusCodes.PARTIAL_CONTENT
 *
 * */
export var PARTIAL_CONTENT = 206;
/**
 * @deprecated Please use StatusCodes.PAYMENT_REQUIRED
 *
 * */
export var PAYMENT_REQUIRED = 402;
/**
 * @deprecated Please use StatusCodes.PERMANENT_REDIRECT
 *
 * */
export var PERMANENT_REDIRECT = 308;
/**
 * @deprecated Please use StatusCodes.PRECONDITION_FAILED
 *
 * */
export var PRECONDITION_FAILED = 412;
/**
 * @deprecated Please use StatusCodes.PRECONDITION_REQUIRED
 *
 * */
export var PRECONDITION_REQUIRED = 428;
/**
 * @deprecated Please use StatusCodes.PROCESSING
 *
 * */
export var PROCESSING = 102;
/**
 * @deprecated Please use StatusCodes.PROXY_AUTHENTICATION_REQUIRED
 *
 * */
export var PROXY_AUTHENTICATION_REQUIRED = 407;
/**
 * @deprecated Please use StatusCodes.REQUEST_HEADER_FIELDS_TOO_LARGE
 *
 * */
export var REQUEST_HEADER_FIELDS_TOO_LARGE = 431;
/**
 * @deprecated Please use StatusCodes.REQUEST_TIMEOUT
 *
 * */
export var REQUEST_TIMEOUT = 408;
/**
 * @deprecated Please use StatusCodes.REQUEST_TOO_LONG
 *
 * */
export var REQUEST_TOO_LONG = 413;
/**
 * @deprecated Please use StatusCodes.REQUEST_URI_TOO_LONG
 *
 * */
export var REQUEST_URI_TOO_LONG = 414;
/**
 * @deprecated Please use StatusCodes.REQUESTED_RANGE_NOT_SATISFIABLE
 *
 * */
export var REQUESTED_RANGE_NOT_SATISFIABLE = 416;
/**
 * @deprecated Please use StatusCodes.RESET_CONTENT
 *
 * */
export var RESET_CONTENT = 205;
/**
 * @deprecated Please use StatusCodes.SEE_OTHER
 *
 * */
export var SEE_OTHER = 303;
/**
 * @deprecated Please use StatusCodes.SERVICE_UNAVAILABLE
 *
 * */
export var SERVICE_UNAVAILABLE = 503;
/**
 * @deprecated Please use StatusCodes.SWITCHING_PROTOCOLS
 *
 * */
export var SWITCHING_PROTOCOLS = 101;
/**
 * @deprecated Please use StatusCodes.TEMPORARY_REDIRECT
 *
 * */
export var TEMPORARY_REDIRECT = 307;
/**
 * @deprecated Please use StatusCodes.TOO_MANY_REQUESTS
 *
 * */
export var TOO_MANY_REQUESTS = 429;
/**
 * @deprecated Please use StatusCodes.UNAUTHORIZED
 *
 * */
export var UNAUTHORIZED = 401;
/**
 * @deprecated Please use StatusCodes.UNPROCESSABLE_ENTITY
 *
 * */
export var UNPROCESSABLE_ENTITY = 422;
/**
 * @deprecated Please use StatusCodes.UNSUPPORTED_MEDIA_TYPE
 *
 * */
export var UNSUPPORTED_MEDIA_TYPE = 415;
/**
 * @deprecated Please use StatusCodes.USE_PROXY
 *
 * */
export var USE_PROXY = 305;
export default {
  ACCEPTED: ACCEPTED,
  BAD_GATEWAY: BAD_GATEWAY,
  BAD_REQUEST: BAD_REQUEST,
  CONFLICT: CONFLICT,
  CONTINUE: CONTINUE,
  CREATED: CREATED,
  EXPECTATION_FAILED: EXPECTATION_FAILED,
  FORBIDDEN: FORBIDDEN,
  GATEWAY_TIMEOUT: GATEWAY_TIMEOUT,
  GONE: GONE,
  HTTP_VERSION_NOT_SUPPORTED: HTTP_VERSION_NOT_SUPPORTED,
  IM_A_TEAPOT: IM_A_TEAPOT,
  INSUFFICIENT_SPACE_ON_RESOURCE: INSUFFICIENT_SPACE_ON_RESOURCE,
  INSUFFICIENT_STORAGE: INSUFFICIENT_STORAGE,
  INTERNAL_SERVER_ERROR: INTERNAL_SERVER_ERROR,
  LENGTH_REQUIRED: LENGTH_REQUIRED,
  LOCKED: LOCKED,
  METHOD_FAILURE: METHOD_FAILURE,
  METHOD_NOT_ALLOWED: METHOD_NOT_ALLOWED,
  MOVED_PERMANENTLY: MOVED_PERMANENTLY,
  MOVED_TEMPORARILY: MOVED_TEMPORARILY,
  MULTI_STATUS: MULTI_STATUS,
  MULTIPLE_CHOICES: MULTIPLE_CHOICES,
  NETWORK_AUTHENTICATION_REQUIRED: NETWORK_AUTHENTICATION_REQUIRED,
  NO_CONTENT: NO_CONTENT,
  NON_AUTHORITATIVE_INFORMATION: NON_AUTHORITATIVE_INFORMATION,
  NOT_ACCEPTABLE: NOT_ACCEPTABLE,
  NOT_FOUND: NOT_FOUND,
  NOT_IMPLEMENTED: NOT_IMPLEMENTED,
  NOT_MODIFIED: NOT_MODIFIED,
  OK: OK,
  PARTIAL_CONTENT: PARTIAL_CONTENT,
  PAYMENT_REQUIRED: PAYMENT_REQUIRED,
  PERMANENT_REDIRECT: PERMANENT_REDIRECT,
  PRECONDITION_FAILED: PRECONDITION_FAILED,
  PRECONDITION_REQUIRED: PRECONDITION_REQUIRED,
  PROCESSING: PROCESSING,
  PROXY_AUTHENTICATION_REQUIRED: PROXY_AUTHENTICATION_REQUIRED,
  REQUEST_HEADER_FIELDS_TOO_LARGE: REQUEST_HEADER_FIELDS_TOO_LARGE,
  REQUEST_TIMEOUT: REQUEST_TIMEOUT,
  REQUEST_TOO_LONG: REQUEST_TOO_LONG,
  REQUEST_URI_TOO_LONG: REQUEST_URI_TOO_LONG,
  REQUESTED_RANGE_NOT_SATISFIABLE: REQUESTED_RANGE_NOT_SATISFIABLE,
  RESET_CONTENT: RESET_CONTENT,
  SEE_OTHER: SEE_OTHER,
  SERVICE_UNAVAILABLE: SERVICE_UNAVAILABLE,
  SWITCHING_PROTOCOLS: SWITCHING_PROTOCOLS,
  TEMPORARY_REDIRECT: TEMPORARY_REDIRECT,
  TOO_MANY_REQUESTS: TOO_MANY_REQUESTS,
  UNAUTHORIZED: UNAUTHORIZED,
  UNPROCESSABLE_ENTITY: UNPROCESSABLE_ENTITY,
  UNSUPPORTED_MEDIA_TYPE: UNSUPPORTED_MEDIA_TYPE,
  USE_PROXY: USE_PROXY
};
<div>
	<span id="daily-notes-date-picker-container">
		<aga-button #datePicker="bsDatepicker"
		            id="daily-notes-date-picker"
		            bsDatepicker
		            placement="bottom"
		            [bsConfig]="{containerClass: 'theme-dark-blue'}"
		            [bsValue]="selectedDate"
		            [color]="datePickerShown ? 'primary' : 'secondary'"
		            [iconUri]="datePickerShown ? 'assets/icons/svg/ic_Date-Picker_Light_40x40.svg' : 'assets/icons/svg/ic_Date-Picker_Dark_40x40.svg'"
		            (onShown)="datePickerShown = true"
		            (onHidden)="datePickerShown = false"
		            (bsValueChange)="goToDay($event)">
		</aga-button>
	</span>
	<img id="close-daily-modal"
	     class="close-daily-modal float-end"
	     src="assets/icons/svg/ic_Close_24x24.svg"
	     alt="close"
	     mat-dialog-close>
</div>
<h1 class="daily-notes-text" [translate]="'dailyNotesDialog.title'"></h1>
<div class="daily-notes-container" *ngIf="dailyNotes.length">
	<div *ngFor="let dailyNote of dailyNotes">
		<aga-daily-note [dailyNote]="dailyNote"
		                [disabled]="!selectedDateIsTodayOrInFuture"
		                (delete)="deleteDailyNote($event)"
		                (update)="updateDailyNote(dailyNote, $event)"></aga-daily-note>
	</div>
</div>
<div class="new-daily-note-container"
     [hidden]="loading || !selectedDateIsTodayOrInFuture"
     [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager, UserRole.MedicalTeam, UserRole.Counselor]">
	<aga-text-editor id="new-daily-note-editor"
	                 [placeholder]="'dailyNotesDialog.placeholder' | translate"
	                 [ngModel]="newDailyNoteText"
	                 (ngModelChange)="newDailyNoteChange($event)"></aga-text-editor>
	<div *ngIf="isEditingNewDailyNote" class="d-inline-flex">
		<img class="no-icon"
		     id="reset-daily-note-icon-new"
		     src="../../../../assets/icons/svg/ic_No_24x24.svg"
		     alt="cancel-daily-note-change"
		     (click)="resetNewDailyNote()">
		<img class="yes-icon"
		     id="save-daily-note-icon-new"
		     src="../../../../assets/icons/svg/ic_Yes_Light_24x24.svg"
		     alt="confirm-daily-note-change"
		     (click)="saveNewDailyNote()">
	</div>
</div>


import {DailyNotesResponse} from '../../services/api-daily-notes.service';

export class DailyNote {
	readonly uuid: string;
	readonly note: string;
	readonly createdByEmployeeUuid: string;
	readonly seen: boolean; // seen by logged in employee
	readonly version: number;

	constructor(params?: {
		uuid: string,
		note: string,
		createdByEmployeeUuid: string,
		seen: boolean,
		version: number
	}) {
		if (params) {
			Object.assign(this, params);
		}
	}

	static deserialize(res: DailyNotesResponse): DailyNote {
		return new DailyNote(res);
	}

	static updateNote(dailyNote: DailyNote, newNote: string): DailyNote {
		return Object.assign(new DailyNote(), dailyNote, {note: newNote});
	}
}

import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from './core/core.module';
import {NavigationBarModule} from './modules/navigation-bar/navigation-bar.module';
import {ConfirmationDialogModule} from './shared/dialogs/confirmation-dialog/confirmation-dialog.module';
import {SnackbarModule} from './shared/snackbar/snackbar.module';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {DialogService} from './shared/dialogs/dialog-service';
import {MatDialogModule} from '@angular/material/dialog';
import {ApiClinicsService} from './shared/services/clinics/api-clinics.service';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {StompjsModule} from '../../../stompjs/src/lib/stompjs.module';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {LoginModule} from './modules/login/login.module';
import {StompConfig} from './configurations/stomp.config';
import {UnsavedChangesCanDeactivateGuard} from './shared/services/unsaved-changes-can-deactivate/unsaved-changes-can-deactivate-guard';
import {LocaleService} from './configurations/locale/locale-service';
import {SentryErrorHandler} from './configurations/sentry/sentry-error-handler';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {CustomTranslateLoader} from './configurations/translate/custom-translate-loader';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		MatSnackBarModule,
		MatDialogModule,
		BsDatepickerModule.forRoot(),
		TimepickerModule.forRoot(),
		CoreModule,
		LoginModule,
		SnackbarModule,
		NavigationBarModule,
		ConfirmationDialogModule,
		StompjsModule.forRoot(StompConfig),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: TranslateLoaderFactory
			},
			defaultLanguage: environment.locale
		}),
	],
	providers: [
		DialogService,
		ApiClinicsService,
		UnsavedChangesCanDeactivateGuard,
		LocaleService,
		{
			provide: LOCALE_ID,
			deps: [LocaleService],
			useFactory: (localeService: LocaleService) => localeService.getLocale()
		},
		{
			provide: ErrorHandler,
			useClass: SentryErrorHandler
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}

export function TranslateLoaderFactory() {
	return new CustomTranslateLoader();
}

import {TranslateLoader} from '@ngx-translate/core';
import {of} from 'rxjs';

export class CustomTranslateLoader implements TranslateLoader {
	constructor() {
	}

	public getTranslation(lang: string): any {
		// eslint-disable-next-line no-undef
		return of(require(`../../../assets/i18n/${lang}.json`));
	}
}

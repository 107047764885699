export enum ServerFieldErrorCode {
	NotEmpty = 'required',
	NotBlank = 'required',
	NotNull = 'required',
	Size = 'minimumLength',
	Email = 'invalidEmail',
	UniqueUsername = 'userExists',
	FutureOrPresent = 'invalidDate',
	UniqueCouponCode = 'uniqueCouponCode'
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {format} from 'date-fns';
import {map} from 'rxjs/operators';

@Injectable()
export class ApiNavigationBarService {
	constructor(private http: HttpClient) {
	}

	getUnreadDailyNotesCount(clinicUuid: string): Observable<number> {
		const params = {
			clinicUuid: clinicUuid,
			date: format(new Date(), 'yyyy-MM-dd')
		};
		return this.http.get(`api/web-ui/medical-record/daily-note/unread/count`, {params: params}).pipe(
			map((res: any) => res.count));
	}
}

import {AppInjector} from '../../app-injector.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ServerErrorCode} from './server-error-code.enum';
import {ServerFieldErrorCode} from './server-field-error-code.enum';
import {SnackbarComponent} from '../../../shared/snackbar/snackbar.component';
import {SnackBarType} from '../../../shared/snackbar/snack-bar-type.enum';

export class ApplicationError {
	readonly errors: Array<ServerOrFieldError>;
	readonly fieldErrors: Array<ServerOrFieldError>;

	constructor(params?: { errors: Array<IServerError>, fieldErrors?: Array<IFieldError> }) {
		this.errors = [];
		this.fieldErrors = [];
		if (params) {
			if (params.errors) {
				params.errors.forEach((error) => this.errors.push(new ServerOrFieldError(ServerErrorCode[error.code], error.message)));
			}
			if (params.fieldErrors) {
				params.fieldErrors.forEach((error) => this.fieldErrors.push(new ServerOrFieldError(ServerFieldErrorCode[error.code], error.message, error.field, error.rejectedValue)));
			}
		}
	}

	getFirstError(): ServerOrFieldError {
		if (this.errors.length > 0) {
			return this.errors[0];
		}
		// When error is 500 or any other error code that does not include a response, show the generic error
		return new ServerOrFieldError(ServerErrorCode.GENERIC_ERROR, '');
	}

	getFirstFieldError(): ServerOrFieldError {
		return this.fieldErrors[0];
	}

	hasSingleError(): boolean {
		return this.errors?.length === 1;
	}

	hasSingleFieldError(): boolean {
		return this.fieldErrors?.length === 1;
	}

	hasErrors(): boolean {
		return this.errors?.length > 0;
	}

	hasFieldErrors(): boolean {
		return this.fieldErrors?.length > 0;
	}

	showError(): void {
		let errors: Array<ServerOrFieldError>;
		if (this.hasErrors()) {
			errors = this.errors;
		} else if (this.hasFieldErrors()) {
			errors = this.fieldErrors;
		} else {
			errors = [new ServerOrFieldError(ServerErrorCode.GENERIC_ERROR, '')];
		}
		const snackBar: MatSnackBar = AppInjector.getInjector().get(MatSnackBar);
		snackBar.openFromComponent(SnackbarComponent, {
			data: {
				snackBarType: SnackBarType.error,
				text: errors?.map(error => `${error.translateError()}\n`)
			},
			panelClass: ['aga-custom-alert'],
			duration: 5000,
			verticalPosition: 'top',
			horizontalPosition: 'right'
		});
	}
}

interface IFieldError {
	field: string,
	rejectedValue: string,
	code: string,
	message: string
}

interface IServerError {
	code: string,
	message: string
}

export class ServerOrFieldError {
	code: ServerErrorCode | ServerFieldErrorCode;
	message: string;
	field?: string;
	rejectedValue?: string;

	constructor(code: ServerErrorCode | ServerFieldErrorCode, message: string, field?: string, rejectedValue?: string) {
		this.code = code;
		this.message = message;
		this.field = field;
		this.rejectedValue = rejectedValue;
	}

	translateError(): string {
		const key = `errors.${this.code}`;
		const translateService = AppInjector.getInjector().get(TranslateService);
		const translation = translateService.instant(key);
		if (translation === key) {
			// no translation available, return generic error message
			return translateService.instant('errors.genericError');
		}
		return translation;
	}
}

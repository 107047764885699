import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticationGuard} from './core/authentication/authentication-guard';
import {LoginPageGuard} from './modules/login/guards/login-page-guard';
import {AuthorizationGuard} from './core/authorization/authorization-guard';
import {UserRole} from './core/models/user-role.enum';
import {AUTHORIZED_FOR_USER_ROLES} from './core/authorization/authorization-keys';
import {ClinicsResolver} from './shared/resolvers/clinics.resolver';

export const routes: Routes = [
	{
		path: 'login',
		canActivate: [LoginPageGuard],
		loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'forgot-password',
		canActivate: [LoginPageGuard],
		loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
	},
	{
		path: 'reset-password/:verificationToken',
		canActivate: [LoginPageGuard],
		loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
	},
	{
		path: '', resolve: {clinics: ClinicsResolver}, children: [
			{
				path: '',
				redirectTo: '/appointments-calendar-preview',
				pathMatch: 'full'
			},
			{
				path: 'home',
				redirectTo: '/appointments-calendar-preview',
				pathMatch: 'full'
			},
			{
				path: 'appointments-calendar-preview',
				canActivate: [AuthenticationGuard],
				loadChildren: () => import('./modules/employee-schedule-calendar/employee-schedule-calendar.module').then(m => m.EmployeeScheduleCalendarModule)
			},
			{
				path: 'appointments-calendar-preview/all-salons',
				canActivate: [AuthenticationGuard],
				loadChildren: () => import('./modules/all-salons/all-salons.module').then(m => m.AllSalonsModule)
			},
			{
				path: 'patient',
				canActivate: [AuthenticationGuard],
				loadChildren: () => import('./modules/patients/patients.module').then(m => m.PatientsModule)
			},
			{
				path: 'manage',
				canActivate: [AuthenticationGuard],
				loadChildren: () => import('./modules/manager/manager.module').then(m => m.ManagerModule)
			},
			{
				path: 'admin',
				canActivate: [AuthenticationGuard],
				loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)
			},
			{
				path: 'work-shift',
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: {[AUTHORIZED_FOR_USER_ROLES]: [UserRole.Concierge]},
				loadChildren: () => import('./modules/work-shift/work-shift.module').then(m => m.WorkShiftModule)
			},
			{
				path: 'room-schedules',
				canActivate: [AuthenticationGuard],
				loadChildren: () => import('./modules/room-schedules/room-schedules.module').then(m => m.RoomSchedulesModule)
			},
			{
				path: 'shipment',
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: {[AUTHORIZED_FOR_USER_ROLES]: [UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager]},
				loadChildren: () => import('./modules/shipment/shipment.module').then(m => m.ShipmentModule)
			},
			{
				path: 'shipped-medicines',
				title: 'pageTitle.shippedMedicines',
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: {[AUTHORIZED_FOR_USER_ROLES]: [UserRole.Concierge, UserRole.Chief, UserRole.MedicalTeam, UserRole.ClinicManager]},
				loadChildren: () => import('./modules/weekly-shipped-medicines/weekly-shipped-medicines.module').then(m => m.WeeklyShippedMedicinesModule)
			},
			{
				path: 'unpaid-orders',
				title: 'pageTitle.unpaidOrders',
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: {[AUTHORIZED_FOR_USER_ROLES]: [UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager]},
				loadChildren: () => import('./modules/unpaid-orders/unpaid-orders.module').then(m => m.UnpaidOrdersModule)
			},
			{
				path: 'blood-appointments',
				title: 'pageTitle.bloodAppointments',
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: {[AUTHORIZED_FOR_USER_ROLES]: [UserRole.Concierge, UserRole.Counselor, UserRole.MedicalTeam, UserRole.Doctor]},
				loadChildren: () => import('./modules/blood-appointments/blood-appointments.module').then(m => m.BloodAppointmentsModule)
			},
			{
				path: 'patient-registration',
				title: 'pageTitle.patientRegistration',
				canActivate: [AuthenticationGuard, AuthorizationGuard],
				data: {[AUTHORIZED_FOR_USER_ROLES]: [UserRole.Concierge, UserRole.Chief, UserRole.Viewer]},
				loadChildren: () => import('./modules/patient-registration/patient-registration.module').then(m => m.PatientRegistrationModule)
			},
			// Redirects for V6 backward support
			{
				path: 'patients/:patientUuid/medical-record-screen',
				redirectTo: '/patient/:patientUuid/medical-record',
				pathMatch: 'full'
			},
			{
				path: 'patients/:patientUuid/patient-details',
				redirectTo: '/patient/:patientUuid/patient-details',
				pathMatch: 'full'
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: [ClinicsResolver]
})
export class AppRoutingModule {
}

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {UserAccountManagementService} from '../../../core/user-account-management/user-account-management.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LoginPageGuard  {

	constructor(private userManagementService: UserAccountManagementService, private router: Router) {
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean |
		UrlTree> | boolean | UrlTree {
		if (this.userManagementService.isUserAuthenticated() && (route.routeConfig.path === 'login' || route.routeConfig.path === 'forgot-password')) {
			this.router.navigate(['home']);
			return false;
		} else {
			return true;
		}
	}
}

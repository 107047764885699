import {Component, HostBinding, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {removeObjectFromArray, replaceObjectInArray} from '../../../../utils/array.util';
import {DailyNote} from './daily-notes/models/daily-note.model';
import {BsDatepickerDirective} from 'ngx-bootstrap/datepicker';
import {ApiDailyNotesService} from './services/api-daily-notes.service';
import {Clinic} from '../../../shared/models/clinic.model';
import {ClinicsService} from '../../../shared/services/clinics/clinics.service';
import {switchMap} from 'rxjs/operators';
import {ApplicationError} from '../../../core/errors/models/application-error.model';
import {Subscription} from 'rxjs';
import {SubscriptionUtil} from '../../../../utils/subscriptions.util';
import {isFuture, isSameDay, isToday} from 'date-fns';
import {UserRole} from '../../../core/models/user-role.enum';

@Component({
	selector: 'aga-daily-notes-modal',
	templateUrl: './daily-notes-modal.component.html',
	styleUrls: ['./daily-notes-modal.component.scss']
})
export class DailyNotesModalComponent implements OnInit, OnDestroy {
	@HostBinding('class.loading') loading: boolean;
	@ViewChild('datePicker') datePicker: BsDatepickerDirective;
	dailyNotes: Array<DailyNote>;
	newDailyNoteText: string = '';
	isEditingNewDailyNote: boolean;
	selectedClinic: Clinic;
	selectedDate = new Date();
	UserRole = UserRole;
	datePickerShown: boolean;
	selectedDateIsTodayOrInFuture = true;
	subscriptions: Array<Subscription>;

	constructor(private clinicsService: ClinicsService, private apiDailyNotesService: ApiDailyNotesService) {
	}

	ngOnInit(): void {
		this.subscriptions = [];
		this.dailyNotes = [];
		this.initDailyNotesForSelectedDate();
	}

	initDailyNotesForSelectedDate(): void {
		this.loading = true;
		this.subscriptions.push(this.clinicsService.getSelectedClinic().pipe(switchMap((selectedClinic: Clinic) => {
			this.selectedClinic = selectedClinic;
			return this.apiDailyNotesService.getDailyNotes(selectedClinic.uuid, this.selectedDate);
		})).subscribe(
			(dailyNotes: Array<DailyNote>) => {
				this.loading = false;
				this.dailyNotes = dailyNotes;
				this.markNotesAsRead();
			}, (error: ApplicationError) => {
				this.loading = false;
				error.showError();
			}
		));
	}

	markNotesAsRead(): void {
		// This is a silent operation, no need to handle success or error
		this.subscriptions.push(this.apiDailyNotesService.markNotesAsRead(this.selectedClinic.uuid, this.selectedDate).subscribe());
	}

	goToDay(date: Date): void {
		if (!isSameDay(date, this.selectedDate)) {
			this.selectedDate = date;
			this.selectedDateIsTodayOrInFuture = isToday(date) || isFuture(date);
			this.initDailyNotesForSelectedDate();
		}
	}

	deleteDailyNote(dailyNote: DailyNote): void {
		this.loading = true;
		this.subscriptions.push(this.apiDailyNotesService.deleteDailyNote(dailyNote.uuid).subscribe(
			() => {
				this.loading = false;
				this.dailyNotes = removeObjectFromArray(this.dailyNotes, dailyNote, 'uuid');
			}, (error: ApplicationError) => {
				this.loading = false;
				error.showError();
			}
		));
	}

	updateDailyNote(oldDailyNote: DailyNote, newDailyNote: DailyNote): void {
		this.loading = true;
		this.subscriptions.push(this.apiDailyNotesService.updateDailyNote(oldDailyNote.uuid, newDailyNote.note, oldDailyNote.version).subscribe(
			(dailyNote: DailyNote) => {
				this.loading = false;
				this.dailyNotes = replaceObjectInArray(this.dailyNotes, oldDailyNote, dailyNote, 'uuid');
			}, (error: ApplicationError) => {
				this.loading = false;
				error.showError();
			}
		));
	}

	newDailyNoteChange(value: string): void {
		this.isEditingNewDailyNote = value !== '';
		this.newDailyNoteText = value;
	}

	resetNewDailyNote(): void {
		this.newDailyNoteText = '';
	}

	saveNewDailyNote(): void {
		this.loading = true;
		this.subscriptions.push(this.apiDailyNotesService.createDailyNote(this.selectedDate, this.selectedClinic.uuid, this.newDailyNoteText).subscribe(
			(dailyNote: DailyNote) => {
				this.dailyNotes.push(dailyNote);
				this.isEditingNewDailyNote = false;
				this.resetNewDailyNote();
				this.loading = false;
			}, (error: ApplicationError) => {
				this.loading = false;
				error.showError();
			}
		));
	}

	ngOnDestroy(): void {
		SubscriptionUtil.unsubscribe(this.subscriptions);
	}
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserAccountManagementService} from './user-account-management/user-account-management.service';
import {AuthenticationService} from './authentication/authentication.service';
import {AuthenticationGuard} from './authentication/authentication-guard';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './authentication/authentication-interceptor/auth.interceptor';
import {ErrorInterceptor} from './errors/error-interceptor';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {jaLocale} from 'ngx-bootstrap/locale';
import {TitleStrategy} from '@angular/router';
import {AgaPageTitleStrategy} from './aga-page-title-strategy.service';

defineLocale('ja', jaLocale);

@NgModule({
	imports: [
		CommonModule,
	],
	providers: [
		UserAccountManagementService,
		AuthenticationService,
		AuthenticationGuard,
		{provide: TitleStrategy, useClass: AgaPageTitleStrategy},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ErrorInterceptor,
			multi: true
		}
	]
})
export class CoreModule {
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApplicationError} from './models/application-error.model';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error) => {
				if (error.status === 0) {
					/**
					 * An error with status 0 means "cancelled request"
					 * This means the user manually cancelled the request most by:
					 * stopping the page load or
					 * changing the url or
					 * refreshing the page
					 */
					return EMPTY;
				}
				return throwError(new ApplicationError(error.error));
			})
		);
	}
}

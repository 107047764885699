import {NgModule} from '@angular/core';
import {DailyNoteComponent} from './daily-notes/daily-note.component';
import {DailyNotesModalComponent} from './daily-notes-modal.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TextEditorModule} from '../../../shared/custom-components/text-editor/text-editor.module';
import {FormsModule} from '@angular/forms';
import {AuthorizationModule} from '../../../core/authorization/authorization.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from '../../../shared/custom-components/button/button.module';
import {ApiDailyNotesService} from './services/api-daily-notes.service';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule(({
	declarations: [DailyNotesModalComponent, DailyNoteComponent],
	imports: [
		CommonModule,
		MatDialogModule,
		BsDatepickerModule,
		FormsModule,
		TooltipModule,
		TranslateModule,
		ButtonModule,
		TextEditorModule,
		AuthorizationModule
	],
	providers: [ApiDailyNotesService]
}))
export class DailyNotesModalModule {
}

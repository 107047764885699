import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {UserAccountManagementService} from './core/user-account-management/user-account-management.service';
import {AppInjector} from './core/app-injector.service';
import {Clinic} from './shared/models/clinic.model';
import {Subscription} from 'rxjs';
import {ApiClinicsService} from './shared/services/clinics/api-clinics.service';
import {ApplicationError} from './core/errors/models/application-error.model';
import {ClinicsService} from './shared/services/clinics/clinics.service';
import {ActivatedRoute, Data, NavigationEnd, Router} from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';
import {NavigationBarOptions} from './modules/navigation-bar/navigation-bar-options';
import {AuthenticationService} from './core/authentication/authentication.service';
import {SubscriptionUtil} from '../utils/subscriptions.util';
import {DialogService} from './shared/dialogs/dialog-service';

@Component({
	selector: 'aga-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	subscriptions: Array<Subscription>;
	clinicsAreLoaded: boolean;
	navBarOptions: NavigationBarOptions;

	constructor(private injector: Injector,
	            private authService: AuthenticationService,
	            private apiClinicService: ApiClinicsService,
	            private clinicsService: ClinicsService,
	            private router: Router,
	            private activatedRoute: ActivatedRoute,
	            private userService: UserAccountManagementService,
	            private dialogService: DialogService) {
		AppInjector.setInjector(injector);
	}

	ngOnInit(): void {
		this.subscriptions = [];
		if (this.userService.isUserAuthenticated()) {
			this.fetchClinicsFromApi();
		} else {
			this.listenForUserLogin();
		}
		this.listenToRouterData();
		this.listenForUserLogout();
	}

	listenToRouterData(): void {
		this.subscriptions.push(this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd),
			map(() => this.activatedRoute),
			map((route) => {
				while (route.firstChild) {
					route = route.firstChild;
				}
				return route;
			}),
			filter((route) => route.outlet === 'primary'),
			mergeMap((route) => route.data))
			.subscribe((data: Data) => {
				this.navBarOptions = data['navigationBarOptions'];
			}));
	}

	fetchClinicsFromApi(): void {
		this.subscriptions.push(this.apiClinicService.fetchClinics().subscribe(
			(clinics: Array<Clinic>) => {
				this.clinicsAreLoaded = true;
				this.clinicsService.setClinics(clinics);
			}, (error: ApplicationError) => error.showError()));
	}

	listenForUserLogin(): void {
		this.subscriptions.push(this.authService.onLogin().subscribe(() => {
			this.fetchClinicsFromApi();
		}));
	}

	isUserLoggedIn(): boolean {
		return this.userService.isUserLoggedIn();
	}

	listenForUserLogout(): void {
		this.subscriptions.push(this.authService.onLogout().subscribe(() => {
			this.dialogService.closeAllDialogs(); // close any open dialogs
			this.router.navigate(['login']);
		}));
	}

	logOut(): void {
		this.subscriptions.push(this.authService.logout().subscribe(
			() => {
				this.userService.clearUserData();
				this.router.navigate(['login']);
				this.clinicsService.clearSelectedClinic();
			}, (error: ApplicationError) => error.showError()
		));
	}

	ngOnDestroy(): void {
		SubscriptionUtil.unsubscribe(this.subscriptions);
	}
}

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Clinic} from '../../models/clinic.model';

@Injectable()
export class ApiClinicsService {

	constructor(private httpClient: HttpClient) {
	}

	public fetchClinics(): Observable<Array<Clinic>> {
		return this.httpClient.get(`api/web-ui/clinic/all-details`).pipe(
			map((clinics: Array<Clinic>) => {
				return clinics.map((clinic: Clinic) => new Clinic(clinic));
			})
		);
	}
}

import {AuthenticationService} from '../core/authentication/authentication.service';
import {RxStomp, RxStompConfig} from '@stomp/rx-stomp';
import {environment} from '../../environments/environment';
import {AppInjector} from '../core/app-injector.service';

export const StompConfig: RxStompConfig = {
	brokerURL: environment.webSocketUrl,
	reconnectDelay: 200,
	beforeConnect: (stompClient: RxStomp): Promise<void> => {
		return new Promise<void>((resolve, _) => {
			const authService = AppInjector.getInjector().get(AuthenticationService);
			if (authService.isApiTokenExpired()) {
				stompClient.deactivate();
			} else {
				stompClient.configure({
					connectHeaders: {
						Authorization: `Bearer ${authService.getApiToken()}`
					}
				});
			}
			resolve();
		});
	}
};

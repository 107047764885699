import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Clinic} from '../models/clinic.model';
import {ClinicsService} from '../services/clinics/clinics.service';
import {Observable} from 'rxjs';

@Injectable()
export class ClinicsResolver  {
	constructor(private clinicsService: ClinicsService) {
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Clinic>> | Promise<Array<Clinic>> | Array<Clinic> {
		return this.clinicsService.getClinics();
	}
}

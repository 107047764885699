import {NgModule} from '@angular/core';
import {StopEventPropagationModule} from '../../shared/directives/stop-event-propagation/stop-event-propagation.module';
import {AuthorizationModule} from '../../core/authorization/authorization.module';
import {NavigationBarComponent} from './navigation-bar.component';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {ButtonModule} from '../../shared/custom-components/button/button.module';
import {SelectButtonModule} from '../../shared/custom-components/select-button/select-button.module';
import {DropDownModule} from '../../shared/custom-components/drop-down/drop-down.module';
import {FormsModule} from '@angular/forms';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {RouterModule} from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import {ReplaceSpacesInStringModule} from '../../shared/pipes/replace-spaces-in-string/replace-spaces-in-string.module';
import {DailyNotesModalModule} from './daily-notes-modal/daily-notes-modal.module';
import {ApiNavigationBarService} from './services/api-navigation-bar.service';
import {RouterLinkInNewTabModule} from '../../shared/directives/router-link-in-new-tab/router-link-in-new-tab.module';

@NgModule(({
	declarations: [NavigationBarComponent],
	imports: [
		CommonModule,
		RouterModule,
		AuthorizationModule,
		StopEventPropagationModule,
		TranslateModule,
		ButtonModule,
		SelectButtonModule,
		DropDownModule,
		FormsModule,
		BsDropdownModule.forRoot(),
		TooltipModule,
		MatDialogModule,
		ReplaceSpacesInStringModule,
		DailyNotesModalModule,
		RouterLinkInNewTabModule
	],
	providers: [ApiNavigationBarService],
	exports: [NavigationBarComponent]
}))

export class NavigationBarModule {
}

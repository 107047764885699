<div class="container navigation-bar-wrapper">
	<img id="navigation-bar"
	     class="navigation-bar-logo desktop d-lg-block d-md-none d-sm-none d-none"
	     src="../../../assets/logo/hairclinic_logo.png"
	     alt="clinic logo"
	     routerLink="/appointments-calendar-preview">
	<img id="navigation-bar-mobile"
	     class="navigation-bar-logo mobile d-md-block d-lg-none"
	     src="../../../assets/logo/hairclinic_mobile_logo.png"
	     alt="clinic logo"
	     routerLink="/appointments-calendar-preview">
	<ng-container *ngIf="!environment.isProduction">
		<div class="environment-tag">
			{{environment.serverType | uppercase}}
		</div>
		<select *ngIf="environment.isDevelopment"
		        id="language-switcher"
		        class="language-switcher"
		        [ngModel]="selectedLocale"
		        (ngModelChange)="changeLocale($event)">
			<option *ngFor="let locale of locales" [value]="locale">{{locale}}</option>
		</select>
	</ng-container>
	<div class="search-bar" (keydown)="keyboardEnterKeyPress(searchQuery, $event.keyCode)">
		<input id="patient-search"
		       class="input-search-bar"
		       type="text"
		       [placeholder]="'patient.searchForPatient' | translate"
		       [(ngModel)]="searchQuery">
		<div id="search-icon-button" class="search-icon">
			<img src="../../../assets/icons/svg/ic_Search_40x40.svg" alt="search" (click)="searchPatients(searchQuery)">
		</div>
	</div>
	<div class="user-and-daily-notes-wrapper">
		<div id="logout-wrapper"
		     class="user-profile btn-group"
		     aria-controls="logout-menu"
		     dropdown
		     dropdownToggle>
			<div class="user-info-wrapper">
				<div class="user-role" [ngClass]="user?.type| lowercase"
				     [translate]="'EmployeeType.'+ user?.type"></div>
				<div class="user-name">{{user?.fullName}}</div>
			</div>
			<aga-button class="profile-icon"
			            id="profile-icon-user"
			            backgroundColor="#58669C"
			            iconUri="assets/icons/svg/ic_Profile_40x40.svg"></aga-button>
			<div *dropdownMenu
			     id="logout-menu"
			     class="dropdown-menu logout-menu"
			     role="menu"
			     aria-labelledby="logout-wrapper"
			     (click)="logOut.emit()">
				<img src="../../../assets/icons/svg/ic_Log-Out_40x40.svg" alt="log out" class="logout-icon">
				<div class="logout-text" [translate]="'navBar.logOut'"></div>
			</div>
		</div>
		<aga-button class="daily-notes-icon"
		            id="daily-notes-no-notification"
		            [backgroundColor]=" hasUnreadDailyNotes ? '#00BB88' : '#58669C'"
		            [iconUri]="hasUnreadDailyNotes ? 'assets/icons/svg/ic_Notifications-Active_24x24.svg' : 'assets/icons/svg/ic_Notifications_24x24.svg'"
		            (click)="openDailyNotesModal()"></aga-button>
	</div>
	<section #clinicDropdown="bs-dropdown"
	         id="clinic-wrapper"
	         class="clinic-wrapper btn-group"
	         aria-controls="clinic-menu"
	         dropdown
	         [insideClick]="true"
	         [ngStyle]="{'background-color': NavBarClinicBackgroundColors[selectedClinic.primaryColorHex]?.clinicBackground,
	         'color': selectedClinic.primaryColorHex}"
	         (onHidden)="cancelClinicState()">
		<div class="d-flex align-items-center" dropdownToggle>
			<div class="clinic-name">
				{{selectedClinic?.name | uppercase}}
			</div>
			<svg class="clinic-menu-image" width="40px" height="40px" viewBox="0 0 40 40" version="1.1"
			     xmlns="http://www.w3.org/2000/svg">
				<title>ic_Header-Clinic_40x40</title>
				<g id="ic_Header-Clinic_40x40" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<path d="M20,15.625 C18.3458659,15.625 17,14.3632507 17,12.8125 C17,11.2617493 18.3458659,10 20,10 C21.6541341,10 23,11.2617493 23,12.8125 C23,13.2440186 22.6269531,13.59375 22.1666667,13.59375 C21.7063802,13.59375 21.3333333,13.2440186 21.3333333,12.8125 C21.3333333,12.1232605 20.7351888,11.5625 20,11.5625 C19.2648112,11.5625 18.6666667,12.1232605 18.6666667,12.8125 C18.6666667,13.5017395 19.2648112,14.0625 20,14.0625 C20.4602865,14.0625 20.8333333,14.4122314 20.8333333,14.84375 C20.8333333,15.2752686 20.4602865,15.625 20,15.625 Z M20.8333333,29.21875 C20.8333333,28.7872314 20.4602865,28.4375 20,28.4375 C19.2648112,28.4375 18.6666667,27.8767395 18.6666667,27.1875 C18.6666667,26.4982605 19.2648112,25.9375 20,25.9375 C20.7351888,25.9375 21.3333333,26.4982605 21.3333333,27.1875 C21.3333333,27.6190186 21.7063802,27.96875 22.1666667,27.96875 C22.6269531,27.96875 23,27.6190186 23,27.1875 C23,25.6367493 21.6541341,24.375 20,24.375 C18.3458659,24.375 17,25.6367493 17,27.1875 C17,28.7382507 18.3458659,30 20,30 C20.4602865,30 20.8333333,29.6502686 20.8333333,29.21875 Z M20.8333333,22.03125 C20.8333333,21.5997314 20.4602865,21.25 20,21.25 C19.2648112,21.25 18.6666667,20.6892395 18.6666667,20 C18.6666667,19.3107605 19.2648112,18.75 20,18.75 C20.7351888,18.75 21.3333333,19.3107605 21.3333333,20 C21.3333333,20.4315186 21.7063802,20.78125 22.1666667,20.78125 C22.6269531,20.78125 23,20.4315186 23,20 C23,18.4492493 21.6541341,17.1875 20,17.1875 C18.3458659,17.1875 17,18.4492493 17,20 C17,21.5507507 18.3458659,22.8125 20,22.8125 C20.4602865,22.8125 20.8333333,22.4627686 20.8333333,22.03125 Z"
					      id="Shape" fill="#FFFFFF"></path>
				</g>
			</svg>
		</div>
		<section *dropdownMenu
		         id="clinic-menu"
		         class="dropdown-menu clinic-menu-wrapper"
		         [ngStyle]="{'background-image': 'linear-gradient(180deg, ' + selectedClinic.primaryColorHex + '20 0%, rgba(255, 255, 255, 0) 100%)'}"
		         aria-labelledby="clinic-wrapper">
			<div class="pb-3 clinic-menu">
				<h3 class="select-branch-text" [translate]="'navBar.clinicSwitcher.selectBranch'"></h3>
				<ng-container *ngFor="let clinic of clinics; let i = index;">
					<aga-select-button *ngIf="clinic.type === ClinicType.Regular"
					                   class="selected-clinic-button"
					                   name="selected-clinic"
					                   [id]="'select-clinic-' + i"
					                   [value]="clinic.name"
					                   [ngModel]="!isSelectingSalon && formSelectedClinic.name"
					                   (ngModelChange)="formSelectedClinic = clinic; saveClinicState()">{{clinic.name}}</aga-select-button>
				</ng-container>
				<aga-select-button class="select-salon-button"
				                   name="select-salon"
				                   [id]="'select-salon'"
				                   [value]="true"
				                   [ngModel]="isSelectingSalon || formSelectedClinic.type === ClinicType.Salon"
				                   (ngModelChange)="isSelectingSalon = true">{{'navBar.clinicSwitcher.salon' | translate}}</aga-select-button>
				<aga-drop-down *ngIf="isSelectingSalon || formSelectedClinic.type === ClinicType.Salon"
				               class="salon-dropdown"
				               id="salon-dropdown"
				               stopClickEventPropagation
				               [label]="'navBar.clinicSwitcher.salon' | translate"
				               [(ngModel)]="formSelectedClinic"
				               (ngModelChange)="saveClinicState()">
					<aga-drop-down-option *ngFor="let salon of salons"
					                      [id]="salon.name | agaReplaceSpacesInString"
					                      [option]="salon">{{salon.name}}</aga-drop-down-option>
				</aga-drop-down>
				<hr class="clinic-menu-separator">
				<aga-drop-down class="default-clinic-dropdown"
				               id="default-clinic-drop-down"
				               stopClickEventPropagation
				               [label]="'navBar.clinicSwitcher.defaultBranch' | translate"
				               [(ngModel)]="formDefaultClinic"
				               (ngModelChange)="saveClinicState()">
					<aga-drop-down-option *ngFor="let clinic of clinics"
					                      [id]="clinic.name | agaReplaceSpacesInString"
					                      [option]="clinic">{{clinic.name}}</aga-drop-down-option>
				</aga-drop-down>
			</div>
		</section>
	</section>
	<section id="hamburger-menu-wrapper"
	         class="hamburger-menu-wrapper btn-group"
	         aria-controls="hamburger-menu"
	         dropdown
	         dropdownToggle
	         [ngStyle]="{'background-color': NavBarClinicBackgroundColors[selectedClinic.primaryColorHex]?.menuBackground}">
		<img class="hamburger-menu-image"
		     src="../../../assets/icons/svg/ic_Header-Menu_White_40x40.svg"
		     alt="hamburger menu">
		<section>
			<ul id="hamburger-menu"
			    class="dropdown-menu hamburger-menu"
			    role="menu"
			    aria-labelledby="hamburger-menu-wrapper"
			    *dropdownMenu>
				<li id="booking-calendar" routerLink="home">
						<span> {{'navBar.bookingCalendar'| translate}}
							<img class="hamburger-link-icon"
							     src="../../../assets/icons/svg/ic_Booking-Calendar_24x24.svg"
							     alt="booking calendar"></span>
				</li>
				<li id="room-schedules" routerLink="room-schedules">
						<span> {{'navBar.roomSchedules' | translate}}
							<img class="hamburger-link-icon"
							     src="../../../assets/icons/svg/ic_Room-Schedule_24x24.svg"
							     alt="room schedules"></span>
				</li>
				<li id="shipment"
				    [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager]"
				    routerLink="shipment"><span> {{'navBar.shipment' | translate}}
					<img class="hamburger-link-icon"
					     src="../../../assets/icons/svg/ic_Shipment-List_24x24.svg"
					     alt="shipment"></span>
				</li>
				<li id="unpaid-delivery-orders"
				    [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager]"
				    routerLink="unpaid-orders">
					<span> {{'navBar.unpaidOrders' | translate}}
						<img class="hamburger-link-icon"
						     src="../../../assets/icons/svg/ic_Delivery-Orders_24x24.svg"
						     alt="delivery orders"></span>
				</li>
				<li id="shipped-medicines"
				    [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager, UserRole.MedicalTeam]"
				    routerLink="shipped-medicines">
					<span> {{'navBar.shippedMedicines' | translate}}
						<img class="hamburger-link-icon"
						     src="../../../assets/icons/svg/ic_Shipped-Medicines_24x24.svg"
						     alt="shipped medicines"></span>
				</li>
				<li id="working-shifts"
				    routerLink="work-shift"
				    [agaShowIfUserAuthorized]="[UserRole.Concierge]">
					<span> {{'navBar.workShift' | translate}}
						<img class="hamburger-link-icon"
						     src="../../../assets/icons/svg/ic_Working-Shifts_24x24.svg"
						     alt="working shifts"></span>
				</li>
				<li id="add-new-patient"
				    routerLink="patient/create-patient"
				    [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.ClinicManager]">
					<span> {{'navBar.addNewPatient' | translate}}
						<img class="hamburger-link-icon"
						     src="../../../assets/icons/svg/ic_Add-Patient_24x24.svg"
						     alt="add new patient"></span>
				</li>
				<li id="blood-appointments"
				    routerLink="blood-appointments"
				    [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Counselor, UserRole.MedicalTeam, UserRole.Doctor]">
					<span> {{'navBar.bloodAppointments' | translate}}
						<img class="hamburger-link-icon"
						     src="../../../assets/icons/svg/ic_Blood-Appointments_24x24.svg"
						     alt="blood appointments"></span>
				</li>
				<li id="patient-registration"
				    routerLink="patient-registration"
				    [agaShowIfUserAuthorized]="[UserRole.Concierge, UserRole.Chief, UserRole.Viewer]">
					<span> {{'navBar.patientRegistration' | translate}}
						<img class="hamburger-link-icon"
						     src="../../../assets/icons/svg/ic_Patient-Registration_Light_24x24.svg"
						     alt="blood appointments">
					</span>
				</li>
			</ul>
		</section>
	</section>
	<section id="people-menu-wrapper"
	         class="people-menu-wrapper btn-group"
	         aria-controls="people-menu"
	         dropdown
	         dropdownToggle
	         [ngStyle]="{'background-color': NavBarClinicBackgroundColors[selectedClinic.primaryColorHex]?.menuBackground}"
	         [agaShowIfUserAuthorized]="[UserRole.ClinicManager]">
		<img class="people-menu-image"
		     src="../../../assets/icons/svg/ic_Header-User-Management-White_40x40.svg"
		     alt="people menu">
		<section>
			<ul *dropdownMenu
			    id="people-menu"
			    class="dropdown-menu people-menu"
			    role="menu"
			    aria-labelledby="people-menu-wrapper">
				<li id="counselors-link" routerLink="manage/counselors">
					<span>{{'navBar.managerMenu.counselors' | translate}}</span>
				</li>
				<li id="concierges-link" routerLink="manage/concierges">
					<span>{{'navBar.managerMenu.concierges' | translate}}</span>
				</li>
				<li id="doctors-link" routerLink="manage/doctors">
					<span>{{'navBar.managerMenu.doctors' | translate}}</span>
				</li>
				<li id="clinic-managers-link"
				    routerLink="manage/clinic-managers"
				    [agaShowIfUserAuthorized]="[UserRole.Admin]">
					<span>{{'navBar.managerMenu.clinicManagers' | translate}}</span>
				</li>
				<li id="medical-team-link" routerLink="manage/medical-team">
					<span>{{'navBar.managerMenu.medicalTeam' | translate}}</span>
				</li>
				<li id="viewers-link" routerLink="manage/viewers">
					<span>{{'navBar.managerMenu.viewers' | translate}}</span>
				</li>
				<li id="medicines-link" routerLink="manage/medicines">
					<span>{{'navBar.managerMenu.medicines' | translate}}</span>
				</li>
				<li id="non-working-day-link" routerLink="manage/non-working-days">
					<span>{{'navBar.managerMenu.nonWorkingDays' | translate}}</span>
				</li>
				<li id="work-shifts-link"
				    routerLink="admin/work-shifts"
				    [agaShowIfUserAuthorized]="[UserRole.Admin]">
					<span>{{'admin.workShift.workShifts' | translate}}</span>
				<li id="coupons-link"
				    routerLink="admin/coupons"
				    [agaShowIfUserAuthorized]="[UserRole.Admin]">
					<span>{{'admin.coupons.coupons' | translate}}</span>
				</li>
				<li id="rooms-link"
				    routerLink="admin/rooms"
				    [agaShowIfUserAuthorized]="[UserRole.Admin]">
					<span>{{'admin.rooms.rooms' | translate}}</span>
				</li>
				<li id="notifications-link"
				    routerLink="admin/notifications"
				    [agaShowIfUserAuthorized]="[UserRole.Admin]">
					<span>{{'admin.notifications.notifications' | translate}}</span>
				</li>
				<li id="income-stats-link"
				    routerLink="admin/income-stats"
				    [agaShowIfUserAuthorized]="[UserRole.Admin]">
					<span>{{'admin.incomeStats.incomeStats' | translate}}</span>
				</li>
				<li id="clinics"
				    routerLink="admin/clinics"
				    [agaShowIfUserAuthorized]="[UserRole.Admin]">
					<span>{{'admin.clinics.clinics' | translate}}</span>
				</li>
			</ul>
		</section>
	</section>
</div>
<div class="navigation-bar-footer" [ngStyle]="{'background-color': selectedClinic.primaryColorHex}"></div>

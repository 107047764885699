import {NgModule} from '@angular/core';
import {ConfirmationDialogComponent} from './confirmation-dialog.component';
import {CommonModule} from '@angular/common';
import {ButtonModule} from '../../custom-components/button/button.module';
import {SafeHtmlSanitizerModule} from '../../pipes/safe-html-sanitizer/safe-html-sanitizer.module';

@NgModule({
	declarations: [ConfirmationDialogComponent],
	imports: [CommonModule, ButtonModule, SafeHtmlSanitizerModule],
	exports: [ConfirmationDialogComponent]
})

export class ConfirmationDialogModule {
}

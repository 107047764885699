import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Clinic, ClinicType} from '../../shared/models/clinic.model';
import {BsDropdownDirective} from 'ngx-bootstrap/dropdown';
import {Subscription} from 'rxjs';
import {UserAccountManagementService} from '../../core/user-account-management/user-account-management.service';
import {SubscriptionUtil} from '../../../utils/subscriptions.util';
import {ClinicsService} from '../../shared/services/clinics/clinics.service';
import {User} from '../../core/models/user.model';
import {LocaleService, SUPPORTED_LOCALES} from '../../configurations/locale/locale-service';
import {environment} from '../../../environments/environment';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DialogService} from '../../shared/dialogs/dialog-service';
import {DailyNotesModalComponent} from './daily-notes-modal/daily-notes-modal.component';
import {MatDialogDefaultConfigs} from '../../shared/mat-dialog-default-configs';
import {NavBarClinicBackgroundColors} from './nav-bar-clinic-background-colors';
import {NavigationBarOptions} from './navigation-bar-options';
import {AuthenticationService} from '../../core/authentication/authentication.service';
import {ApiNavigationBarService} from './services/api-navigation-bar.service';
import {UserRole} from '../../core/models/user-role.enum';
import {openInNewWindow} from '../../../utils/browser.util';
import {Location} from '@angular/common';

const KEYBOARD_ENTER_KEY_CODE = 13;

@Component({
	selector: 'aga-navigation-bar',
	templateUrl: './navigation-bar.component.html',
	styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnDestroy {
	@Input() navigationBarOptions: NavigationBarOptions;
	@Output() logOut: EventEmitter<any> = new EventEmitter<any>();
	hasUnreadDailyNotes: boolean;
	locales = SUPPORTED_LOCALES;
	environment = environment;

	@ViewChild('clinicDropdown') clinicDropdown: BsDropdownDirective;
	selectedLocale: string;
	searchQuery: string;
	user: User;
	clinics: Array<Clinic>;
	salons: Array<Clinic>;
	selectedClinic: Clinic;
	defaultClinic: Clinic;
	formSelectedClinic: Clinic;
	formDefaultClinic: Clinic;
	isSelectingSalon: boolean;
	subscriptions: Array<Subscription>;
	NavBarClinicBackgroundColors = NavBarClinicBackgroundColors;
	UserRole = UserRole;
	ClinicType = ClinicType;

	constructor(
		private authService: AuthenticationService,
		private userService: UserAccountManagementService,
		private apiNavigationService: ApiNavigationBarService,
		private clinicsService: ClinicsService,
		private localeService: LocaleService,
		private router: Router,
		private route: ActivatedRoute,
		private location: Location,
		private dialogService: DialogService,
		private elementRef: ElementRef,
		private cdr: ChangeDetectorRef) {
	}

	ngOnInit(): void {
		this.subscriptions = [];
		this.initClinics();
		this.initUser();
		this.checkAndFetchSearchQueryFromRoute();
		if (!environment.isProduction) {
			this.initDefaultLocale();
		}
	}

	initClinics(): void {
		this.subscriptions.push(this.clinicsService.getClinics().subscribe(
			(clinics: Array<Clinic>) => {
				this.clinics = clinics;
				this.salons = this.clinics.filter(clinic => clinic.type === ClinicType.Salon);
				this.initSelectedAndDefaultClinics();
			})
		);
	}

	initUser(): void {
		this.user = this.userService.currentUser;
	}

	checkAndFetchSearchQueryFromRoute(): void {
		this.subscriptions.push(this.route.queryParams.subscribe((params: Params) => this.searchQuery = params['search_query']?.trim()));
	}

	initDefaultLocale(): void {
		this.selectedLocale = this.localeService.getLocale();
	}

	keyboardEnterKeyPress(searchQuery: string, keyCode: number): void {
		if (keyCode === KEYBOARD_ENTER_KEY_CODE) {
			this.searchPatients(searchQuery);
		}
	}

	searchPatients(searchQuery: string): void {
		if (searchQuery) {
			const trimmedQuery = searchQuery.trim();
			if (trimmedQuery) {
				if (this.location.path().includes('patient?')) {
					this.router.navigate(['patient'], {queryParams: {search_query: trimmedQuery}});
				} else {
					openInNewWindow(this.router, ['patient'], {queryParams: {search_query: trimmedQuery}});
				}
			}
		}
	}

	initSelectedAndDefaultClinics(): void {
		this.subscriptions.push(this.clinicsService.getSelectedClinic().subscribe(
			(selectedClinic: Clinic) => {
				this.selectedClinic = selectedClinic;
				this.formSelectedClinic = selectedClinic;
				this.elementRef.nativeElement.style.setProperty('--clinic-color', selectedClinic.primaryColorHex);
				this.checkUnreadDailyNotes();
				this.cdr.detectChanges();
			}));

		this.subscriptions.push(this.clinicsService.getDefaultClinic().subscribe(
			(defaultClinic: Clinic) => {
				this.defaultClinic = defaultClinic;
				this.formDefaultClinic = defaultClinic ? defaultClinic : null;
			}));
	}

	checkUnreadDailyNotes(): void {
		this.subscriptions.push(this.apiNavigationService.getUnreadDailyNotesCount(this.selectedClinic.uuid)
			.subscribe((count: number) => this.hasUnreadDailyNotes = count > 0));
	}

	changeLocale(locale: string): void {
		this.selectedLocale = locale;
		this.localeService.setLocale(locale);
	}

	saveClinicState(): void {
		this.resetAppointmentCalendarRouterParams();
		if (this.selectedClinic.uuid !== this.formSelectedClinic.uuid) {
			this.clinicsService.setSelectedClinic(this.clinics.find((clinic: Clinic) => clinic.uuid === this.formSelectedClinic.uuid));
		}
		if (this.defaultClinic.uuid !== this.formDefaultClinic.uuid) {
			this.clinicsService.setDefaultClinic(this.formDefaultClinic);
		}
		this.isSelectingSalon = false;
		this.clinicDropdown.isOpen = false;
	}

	resetAppointmentCalendarRouterParams(): void {
		if (this.router.url.includes('appointments-calendar-preview?clinicUuid')) {
			this.router.navigate([], {
				queryParams: {
					'clinicUuid': null,
					'appointmentUuid': null
				},
				queryParamsHandling: 'merge'
			});
		}
	}

	cancelClinicState(): void {
		this.formSelectedClinic = this.selectedClinic;
		this.formDefaultClinic = this.defaultClinic;
		this.clinicDropdown.isOpen = false;
		this.isSelectingSalon = false;
	}

	openDailyNotesModal(): void {
		const dailyNotesModalRef = this.dialogService.openDialog(DailyNotesModalComponent, {
			...MatDialogDefaultConfigs.rightAlignedDefaultConfig,
			disableClose: true,
			panelClass: 'shared-dialog-options'
		});
		this.subscriptions.push(dailyNotesModalRef.afterClosed().subscribe(() => this.hasUnreadDailyNotes = false));
	}

	ngOnDestroy(): void {
		SubscriptionUtil.unsubscribe(this.subscriptions);
	}
}
